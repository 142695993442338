import { Button } from '/features/buildingBlocks/Button'
import { useTranslate } from '/machinery/I18n'
import cssStyleContext from '/cssGlobal/style-context.css'

import styles from './ApplyButton.css'

export function ApplyButton({ layoutClassName = undefined }) {
  const { __ } = useTranslate()

  return (
    <div data-style-context={cssStyleContext.contextBlack} className={cx(styles.component, layoutClassName)}>
      <div className={styles.buttonContainer}>
        <Button href='#form' dataX='scroll-to-form' layoutClassName={styles.buttonLayout}>
          {__`job-detail-apply-now`}
        </Button>
      </div>
    </div>
  )
}
