import { useScrollActivity } from '/machinery/useScrollActivity'
import { useScrollDepth } from '/machinery/useScrollDepth'
import { useGetHashFromUrl } from '/machinery/useGetHashFromUrl'

import { ApplyButton } from '/features/pageOnly/jobDetail/ApplyButton'

import styles from './MobileApplyButton.css'

export function MobileApplyButton({ layoutClassName = undefined }) {
  const { hasScrolled, direction } = useScrollActivity({ delta: 100 })
  const isAboveFold = useScrollDepth({ offset: -10 })
  const hash = useGetHashFromUrl()
  const isVisible = !hasScrolled || direction === 'up'

  const isHidden = React.useMemo(() => {
    if (!hash) return false
    return hash === 'form' || !isAboveFold
  }, [hash, isAboveFold])

  return (
    <div className={cx(styles.component, isVisible && styles.isVisible, isHidden && styles.isHidden, layoutClassName)}>
      <ApplyButton />
    </div>
  )
}

